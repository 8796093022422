import { tenderColumns, filterSelects, filterRanges } from "./TenderListData";
import _ from 'lodash'

export const TenderListFullBasicMixin = {
    data() {
        return {
            _filterSelects: [],
            _filterRanges: {},
        };
    },
    beforeCreate() {
        this.$store.commit("tender/setColumnsInitial", tenderColumns)
        this._filterSelects = filterSelects;
        this._filterRanges = filterRanges;
    },
    computed: {
        tendersList() {
            return this.$store.getters["tender/list"];
        },
        _tenderColumns() {
            return this.$store.getters["tender/columns"];
        },
        _tenderColumnsShown() {
            return this._tenderColumns.filter((item) => item.show !== false)
        },
        tenderLoading() {
            return this.$store.getters["tender/loading"];
        },
        appliedFilters() {
            return this.$store.getters["tender/appliedFilters"];
        },
        isHideInactive() {
            return this.$store.getters["tender/isHideInactive"];
        },
        showSetup() {
            return this.$store.getters["tender/showSetup"];
        },
        currentPage() {
            return this.$store.getters["tender/currentPage"];
        },
        perPage() {
            return this.$store.getters["tender/perPage"];
        },
        totalCount() {
            return this.$store.getters["tender/totalCount"];
        },
    },
    mounted() {
    },
    methods: {
        updateList() {
            this.$store.dispatch("tender/load", { showMore: false, demoPage: false });
            this.syncFieldsStatesFromFilter();
        },
        changeFilterValue(column, value) {
            this.$store.commit("tender/setFilterValue", { column, value });
            this.updateList()
        },
        getSelectedTitle(column, row) {
            const selectList = this._filterSelects[column.selectList];
            if (!selectList) return '';
            const field = column.field;

            const selectedItemId = row[field];
            const selectedItem = selectList.find(item => item.id === selectedItemId);

            return selectedItem ? selectedItem.title : '';
        },
        resetFilters(filterName = null) {
            if (filterName === null) {
                this.$store.commit("tender/resetFilters")
                this.updateList()
            } else {
                this.changeFilterValue(filterName, null)
            }
        },
        syncFieldsStatesFromFilter() {
            for (let i = 0; i < this._tenderColumns.length; i++) {
                let column = this._tenderColumns[i];
                const field = column.field;
                let selected = column.selectedItem;

                if (field in this.appliedFilters) {
                    const filterValue = this.appliedFilters[field].value;
                    if (filterValue !== selected) {
                        selected = filterValue;
                    }
                } else {
                    this._tenderColumns[i].selectedItem = null;
                    if (column.rangeable && (column.range[0] !== 1 || column.range[1] !== column.maxRange)) {
                        column.range[0] = 1;
                        column.range[1] = column.maxRange
                    }
                }
            }
        },
        forceUpdate() {
        },
        toggleShowSetup() {
            this.$store.commit("tender/toggleShowSetup");
        },
        updateRange(column, range) {
            const rangeName = column.rangeName;
            let leftRange = range[0];
            let rightRange = range[1];

            if (leftRange === null) leftRange = this._filterRanges[rangeName][0];
            if (rightRange === null) rightRange = this._filterRanges[rangeName][1];

            leftRange = parseInt(leftRange);
            rightRange = parseInt(rightRange);

            this._filterRanges[rangeName][0] = leftRange;
            this._filterRanges[rangeName][1] = rightRange;
            this._filterRanges = { ...this._filterRanges }

            column.range = this._filterRanges[rangeName];

            this.changeFilterValue(column.field, column.range)
            //this.forceUpdate();
        },
    },
    watch: {
    }
};
