<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.25 8.25H13.75V13.75H8.25V8.25Z" fill="#A01B5B" />
    <path
      d="M17.4163 15.5848V6.41811C17.4163 5.40703 16.5941 4.58478 15.583 4.58478H6.41634C5.40526 4.58478 4.58301 5.40703 4.58301 6.41811V15.5848C4.58301 16.5959 5.40526 17.4181 6.41634 17.4181H15.583C16.5941 17.4181 17.4163 16.5959 17.4163 15.5848ZM6.41634 6.41811H15.583L15.5848 15.5848H6.41634V6.41811Z"
      fill="#A01B5B"
    />
  </svg>
</template>

<script>
export default {};
</script>