<template>
  <b-table
    :data="tendersList"
    class="custom-table border-top is-hidden-mobile"
    :bordered="true"
    icon-pack="fas"
    :loading="tenderLoading"
    :mobile-cards="false"
  >
    <template #empty>
      <div class="has-text-centered">Нет записей</div>
    </template>

    <b-table-column
      class="is-hidden-mobile"
      v-for="column in _tenderColumnsShown"
      :key="column.id"
    >
      <template v-slot:header>
        <div
          class="table-header-title"
          :style="{ 'min-width': column.width + 'px' }"
        >
          {{ column.label }}
        </div>
      </template>

      <template v-slot="props">
        <div
          v-if="column.useDatesTemplate"
          class="is-size-7 has-text-weight-medium is-flex"
        >
          <span class="mr-2 mt-1">
            <time-icon />
          </span>

          <span>
            с
            {{ props.row["date_from"] | moment("DD.MM.YYYY") }}
            <br />
            по {{ props.row["date_to"] | moment("DD.MM.YYYY") }}
          </span>
        </div>

        <div v-else-if="column.useLinkTemplate" class="has-text-violet">
          <router-link :to="{name: 'info', params: {id: props.row.id}}">{{props.row[column.field]}}</router-link>
        </div>

        <div v-else-if="column.useNumberTemplate">
          {{ formatSpacesThousands(props.row[column.field]) }}
        </div>

        <div v-else-if="column.useStatusTemplate">
          <TndStatus :statusId="props.row.current_status_id" />
        </div>

        <div v-else-if="column.useSelectTemplate">
          <div v-bind:class="[column.useVioletColor ? 'has-text-violet' : '']">
            {{ getSelectedTitle(column, props.row) }}
          </div>
        </div>

        <div v-else class="is-size-6-5">
          <div v-bind:class="{ 'has-text-violet': column.useVioletColor }">
            <ShowMore
              :text="props.row[column.field]"
              v-if="column.hideUnderShowMore"
            />
            <span v-else>{{ props.row[column.field] }}</span>
          </div>
        </div>
      </template>

      <template v-slot:subheading>
        <b-field v-if="column.selectable">
          <b-select
            @input="changeFilterValue(column.field, column.selectedItem)"
            v-model="column.selectedItem"
            class="bordered"
            :placeholder="column.placeholder"
          >
            <option
              v-for="option in _filterSelects[column.selectList]"
              :value="option.id"
              :key="option.id"
            >
              {{ option.title }}
            </option>
          </b-select>
        </b-field>

        <b-field v-if="column.iSearchable">
          <b-field>
            <b-input
              class="bordered"
              :placeholder="column.placeholder"
              icon="search"
              v-model="column.selectedItem"
              @keyup.native.enter="
                changeFilterValue(column.field, column.selectedItem)
              "
              @blur="changeFilterValue(column.field, column.selectedItem)"
            />
          </b-field>
        </b-field>

        <b-field v-if="column.rangeable">
          <b-dropdown
            position="is-bottom-left"
            append-to-body
            aria-role="menu"
            trap-focus
            class="custom-dropdown"
          >
            <template #trigger="{ active }">
              <div class="field">
                <div class="control bordered">
                  <b-button
                    :label="column.placeholder"
                    type=""
                    :icon-right="active ? 'menu-up' : 'menu-down'"
                  />
                </div>
              </div>
            </template>

            <b-dropdown-item
              aria-role="menu-item"
              :focusable="false"
              custom
              paddingless
            >
              <div class="notification p-4 custom-collapse">
                <div class="content">
                  <div class="randebox">
                    <div class="mb-2">
                      <span>Цена</span>
                    </div>

                    <div class="is-flex">
                      <input
                        class="input"
                        type="text"
                        placeholder=""
                        v-model="_filterRanges[column.rangeName][0]"
                        @input="
                          (event) =>
                            updateRange(column, [event.target.value, null])
                        "
                      />
                      <div class="has-text-centered ml-3 mr-3 dash">
                        <span>-</span>
                      </div>
                      <input
                        class="input"
                        type="text"
                        placeholder=""
                        v-model="_filterRanges[column.rangeName][1]"
                        @input="
                          (event) =>
                            updateRange(column, [null, event.target.value])
                        "
                      />
                    </div>

                    <div>
                      <b-field>
                        <b-slider
                          v-model="column.range"
                          :min="1"
                          :max="column.maxRange"
                          :step="1000"
                          rounded
                          type="is-danger"
                          :tooltip="false"
                          @dragend="updateRange(column, column.range)"
                        >
                        </b-slider>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
      </template>
    </b-table-column>
  </b-table>
</template>

<script>
import { TenderListFullBasicMixin } from "./TenderListFullBasicMixin";
import TenderMobileCard from "./TenderMobileCard";
import TimeIcon from "@/components/icons/TimeIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import GearIcon from "@/components/icons/GearIcon.vue";
import ShowMore from "@/components/common/ShowMore";
import TndStatus from "@/components/common/TndStatus";

export default {
  name: "TenderListFull3",
  components: {
    TenderMobileCard,
    TimeIcon,
    CloseIcon,
    GearIcon,
    ShowMore,
    TndStatus,
  },
  mixins: [TenderListFullBasicMixin],
};
</script>

<style scoped>
.table-header-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6f6f6f;
}
</style>