<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7025 5.15566L12.8443 2.29746C12.7125 2.16562 12.5508 2.06895 12.375 2.01621V1.96875H2.53125C2.22012 1.96875 1.96875 2.22012 1.96875 2.53125V15.4688C1.96875 15.7799 2.22012 16.0312 2.53125 16.0312H15.4688C15.7799 16.0312 16.0312 15.7799 16.0312 15.4688V5.9502C16.0312 5.65137 15.9135 5.3666 15.7025 5.15566ZM6.75 3.23438H11.25V5.0625H6.75V3.23438ZM14.7656 14.7656H3.23438V3.23438H5.625V5.625C5.625 5.93613 5.87637 6.1875 6.1875 6.1875H11.8125C12.1236 6.1875 12.375 5.93613 12.375 5.625V3.61758L14.7656 6.0082V14.7656ZM9 7.76953C7.60254 7.76953 6.46875 8.90332 6.46875 10.3008C6.46875 11.6982 7.60254 12.832 9 12.832C10.3975 12.832 11.5312 11.6982 11.5312 10.3008C11.5312 8.90332 10.3975 7.76953 9 7.76953ZM9 11.707C8.22305 11.707 7.59375 11.0777 7.59375 10.3008C7.59375 9.52383 8.22305 8.89453 9 8.89453C9.77695 8.89453 10.4062 9.52383 10.4062 10.3008C10.4062 11.0777 9.77695 11.707 9 11.707Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>