<template>
  <div class="shadow-tablet br-3 custom-border-tablet mb-5-5-tablet">
    <tender-header />

    <div class="table-container">
      <div class="p-4 is-hidden-tablet custom-table">
        <TenderMobileCard
          v-for="tender in tendersList"
          :key="tender.id"
          :tender="tender"
        />
      </div>

      <TenderListTable />
      <TenderListSetup />
    </div>
  </div>
</template>

<script>
import { TenderListFullBasicMixin } from "./TenderListFullBasicMixin";
import TenderMobileCard from "./TenderMobileCard";
import TenderHeader from "./TenderHeader";
import TenderListTable from "./TenderListTable";
import TenderListSetup from "./TenderListSetup";
import TimeIcon from "@/components/icons/TimeIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";

export default {
  components: {
    TenderMobileCard,
    TenderHeader,
    TenderListTable,
    TimeIcon,
    CloseIcon,
    TenderListSetup,
  },
  mixins: [TenderListFullBasicMixin],
  mounted() {
    this.updateList();
  },
};
</script>

<style scoped>
.table-header-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6f6f6f;
}
</style>
